import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import './Login.css';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../lib/contextLib';
import LoaderButton from '../components/LoaderButton';
import { onError } from '../lib/errorLib';
import { useFormFields } from '../lib/hooksLib';

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
    } catch (e) {
      onError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className='Login'>
      <Form onSubmit={handleSubmit}>
        <div className="d-grid gap-3">
          <Form.Group controlId='email'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              size='lg'
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              size='lg' 
              value={fields.password}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <LoaderButton
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Login
          </LoaderButton>
        </div>
      </Form>
    </div>
  );
}