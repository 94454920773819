import { useContext, createContext } from "react";

export type GlobalContent = {
  isAuthenticated: boolean
  userHasAuthenticated:(v: boolean) => void
}

export const AppContext = createContext<GlobalContent>({} as GlobalContent);

export function useAppContext() {
  return useContext(AppContext);
}