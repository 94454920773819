import { useState } from "react";

export function useFormFields(initialState: any) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event: React.SyntheticEvent) {
      if (event.target instanceof HTMLInputElement) {
        setValues({
          ...fields,
          [event.target.id]: event.target.value,
        });
      }
    },
  ];
}